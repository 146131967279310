import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/gregoirethibaud/Desktop/Brusketa/Developpement/brusketa/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Card from './Card';
import { Playground, Props } from 'docz';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "card"
    }}>{`Card`}</h1>
    <p>{`Card component`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Card} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<Card />'} __scope={{
      props,
      DefaultLayout,
      Card,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Card mdxType="Card" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      